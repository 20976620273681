var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("validation-provider", {
    attrs: {
      mode: _vm.vmode,
      vid: _vm.vid,
      name: _vm.name,
      rules: _vm.isAssistence ? _vm.rules : {},
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ dirty, validated, valid, errors }) {
          return [
            _vm.isAssistence
              ? _c(
                  "b-form-group",
                  {
                    class: {
                      asterisk:
                        _vm.isAssistence &&
                        (Object.keys(_vm.rules).includes("required") ||
                          (Object.keys(_vm.rules).includes("required_if") &&
                            !valid)),
                    },
                    attrs: {
                      id: `input-group-${_vm.id}`,
                      label: _vm.label,
                      "label-for": `input-${_vm.id}`,
                    },
                  },
                  [
                    _vm.controlType === "text"
                      ? _c("b-form-input", {
                          attrs: {
                            id: `input-${_vm.id}`,
                            name: `input-${_vm.id}`,
                            type: _vm.type,
                            state: _vm.getValidationState({
                              dirty,
                              validated,
                              valid,
                              errors,
                            }),
                            "aria-describedby": `input-${_vm.id}-live-feedback`,
                            autocomplete:
                              _vm.type === "password" ? "on" : "off",
                          },
                          on: {
                            blur: _vm.onBlur,
                            input: (val) => {
                              if (_vm.textcase === "upper") {
                                _vm.inputVal = val.toUpperCase()
                              }
                            },
                          },
                          model: {
                            value: _vm.inputVal,
                            callback: function ($$v) {
                              _vm.inputVal = $$v
                            },
                            expression: "inputVal",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "b-form-invalid-feedback",
                      { attrs: { id: `input-${_vm.id}-live-feedback` } },
                      [_vm._v(_vm._s(errors[0]))]
                    ),
                  ],
                  1
                )
              : _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: `input-group-${_vm.id}`,
                      label: _vm.label,
                      "label-for": `input-${_vm.id}`,
                    },
                  },
                  [
                    _vm.controlType === "text"
                      ? _c("b-form-input", {
                          attrs: {
                            id: `input-${_vm.id}`,
                            name: `input-${_vm.id}`,
                            type: _vm.type,
                            state: _vm.getValidationState({
                              dirty,
                              validated,
                              valid,
                              errors,
                            }),
                            disabled: "",
                            "aria-describedby": `input-${_vm.id}-live-feedback`,
                          },
                          model: {
                            value: _vm.inputVal,
                            callback: function ($$v) {
                              _vm.inputVal = $$v
                            },
                            expression: "inputVal",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "b-form-invalid-feedback",
                      { attrs: { id: `input-${_vm.id}-live-feedback` } },
                      [_vm._v(_vm._s(errors[0]))]
                    ),
                  ],
                  1
                ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }